/* src/MarqueeComponent.css */
.marquee-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 150px;
    /* Adjust height to fit the larger cards */
    overflow: hidden;
    /* Ensure content overflow is hidden */
}

.marquee-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    width: 150px;
    /* Adjust width to fit the larger cards */
    margin-right: 60px;
    /* Increase horizontal gap between cards */
}

.marquee-image {
    margin-bottom: 10px;
}

.marquee-logo {
    height: 80px;
    /* Adjust size of the logo */
    max-height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
}

.marquee-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.line {
    width: 50%;
    height: 1px;
    background-color: #ccc;
    margin: 5px 0;
}

.company-name {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}