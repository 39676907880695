.feature-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.feature-icon {
    flex-shrink: 0;
}

.feature-text {
    margin: 0;
}

.feature-text h4,
.feature-text p {
    margin: 0;
    text-align: left;
}

.feature-item.feature-style-left .feature-text h4 {
    margin-bottom: 5px;
}

.feature-col {
    padding-bottom: 20px;

}