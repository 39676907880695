.events-container {
    padding: 20px;
}

h2 {
    margin-bottom: 20px;
}

.events-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.event-card {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.event-card:hover {
    transform: scale(1.05);
}

.event-image {
    width: 100%;
    height: 180px; /* Fixed height for the image */
    object-fit: cover; /* Ensure the image covers the entire area */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.event-content {
    flex-grow: 1; /* Allows the content to take up remaining space */
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.event-details {
    margin-top: 10px;
    font-size: 0.9em;
}
