.team-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 400px; /* Set a minimum height for the card */
    height: auto; /* Allow the card height to grow based on content */
}

.team-img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 300px; /* Set a fixed height for the images */
    flex-shrink: 0; /* Prevent the image container from shrinking */
}

.team-img img {
    width: 100%;
    height: 100%; /* Ensures the image covers the container */
    object-fit: cover; /* Ensures the image covers the area properly */
    -webkit-object-fit: cover; /* For older versions of Safari */
}

.team-socials {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
}

.team-socials li {
    display: inline-block;
    margin-right: 10px;
}

.team-socials li:last-child {
    margin-right: 0;
}

.team-content {
    padding: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1; /* Allows the content to take up remaining space */
}

.team-info {
    text-align: center;
}
